import styled, { keyframes } from 'styled-components/macro'; 
import { object, string } from 'prop-types';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  display: inline-block;
  width: ${props => props.diameter};
  height: ${props => props.diameter};

  &:after {
    content: "";
    display: block;
    width: ${props => props.diameter};
    height: ${props => props.diameter};
    margin: 1px;
    border-radius: 50%;
    border: calc(${props => props.diameter} / 16) solid ${props => props.theme.primary};
    border-color: ${props => props.theme.primary} transparent ${props => props.theme.primary} transparent;
    animation: ${rotate} ${props => props.duration} linear infinite;
  }
`;

Spinner.propTypes = {
  diameter: string,
  duration: string,
  theme: object,
};

Spinner.defaultProps = {
  diameter: "64px",
  duration: "1.2s",
};

export default Spinner;
