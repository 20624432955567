const moveStorage = (fromStorage, toStorage) => {
  for (let i = 0; i < fromStorage.length; i++) {
    const key = fromStorage.key(i);
    const value = fromStorage.getItem(key);
    toStorage.setItem(key, value);
  }
  fromStorage.clear();
};

class StorageSwitcher {
  constructor() {
    this.backend = localStorage.getItem("useLocalStorage") ? localStorage : sessionStorage;
  }

  useSessionStorage = () => {
    if (this.backend === sessionStorage) return;

    localStorage.removeItem("useLocalStorage");
    moveStorage(localStorage, sessionStorage);
    this.backend = sessionStorage;
  }

  useLocalStorage = () => {
    if (this.backend === localStorage) return;

    localStorage.setItem("useLocalStorage", true);
    moveStorage(sessionStorage, localStorage);
    this.backend = localStorage;
  }

  toggle = () => {
    if (this.backend === localStorage) {
      this.useSessionStorage();
    } else {
      this.useLocalStorage();
    }
  }
}

export default new Proxy(new StorageSwitcher(), {
  get: (switcher, prop) => prop in switcher ? switcher[prop] : switcher.backend[prop].bind(switcher.backend),
});
