import React from 'react';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components/macro'
import { BrowserRouter as Router, Redirect, Route, Link, Switch } from 'react-router-dom';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { ReactComponent as LogoSvg } from './logo.svg';
import { ReactComponent as OctopusSvg } from './octopus.svg';
import Authenticate from './Authenticate';
import Login from './Login';
import Profile from './Profile';
import Home from './Home';
import client from './client';
import storage from './storage';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Lato|Righteous&display=swap');

  body {
    font-family: 'Lato', sans-serif;
    background-color: ${props => props.theme.primaryDarkest};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    font-family: 'Lato', sans-serif;
    font-weight: bold;
  }

  a:link {
    color: ${props => props.theme.white};
  }
  a:visited {
    color: ${props => props.theme.white};
  }
  a:active {
    color: ${props => props.theme.white};
  }
`

const Grid = styled.div`
  display: grid;
  height: 100vh;
  grid-gap: 1em;
  grid-template-columns: 1fr;
  grid-template-rows: 64px 1fr 32px;
  grid-template-areas:
  "header"
  "content"
  "footer"
`;

const Content = styled.div`
  grid-area: content;
  color: ${props => props.theme.white};
  background-color: ${props => props.theme.primaryDarkest};
`;

const About = () => <div>Octopus by Chameleon Design from the Noun Project</div>;

const Header = styled.header`
  grid-area: header;
  font-family: 'Righteous', cursive;
  font-size: 2em;
  background-color: ${props => props.theme.primaryDarkest};
  color: ${props => props.theme.white};
`;

const Footer = styled.footer`
  font-family: 'Righteous', cursive;
  grid-area: footer;
  background-color: ${props => props.theme.primaryDarkest};
  color: ${props => props.theme.white};
`;

const NavRight = styled.nav`
  float: right;
  margin-right: 4px;
`;

const NavLink = styled(Link)`
  padding: 2px;
  margin: 2px;
  text-decoration: none;
  font-size: .5em;

  &:hover {
    border-bottom: ${props => props.theme.tertiaryBrightest} 2px solid;
  }
  &:active {
    border-bottom: ${props => props.theme.tertiaryBright} 2px solid;
  }
`;

const Logo = styled(LogoSvg)`
  margin-left: 4px;
  fill: ${props => props.theme.tertiaryBrightest};
`;

const Octopus = styled(OctopusSvg)`
  margin-left: 4px;
  fill: ${props => props.theme.white};
`;

const theme = {
  primary: "#2D4671",
  primaryDark: "#2B3444",
  primaryDarkest: "#222528",
  primaryBright: "#1A4A9D",
  primaryBrightest: "#0B55D4",
  secondary: "#3D3175",
  secondaryDark: "#322E47",
  secondaryDarkest: "#25242A",
  secondaryBright: "#371FA4",
  secondaryBrightest: "#330ED8",
  tertiary: "#246B61",
  tertiaryDark: "#26413D",
  tertiaryDarkest: "#202625",
  tertiaryBright: "#0B9582",
  tertiaryBrightest: "#00D0B3",
  white: "#FFFFFF",
  black: "#000000",
};

const USER = gql`
  query User {
    user {
      firstName
      token
    }
  }
`;

const NavLinks = ({ data }) => (
  <NavRight>
    {data ? (
      ["Profile", "Logout"].map(link => (
        <NavLink key={link} to={`/${link.toLowerCase()}`}>{link}</NavLink>
      ))) : (
        <NavLink to="/login">Login</NavLink>
      )}
    </NavRight>
);

const Logout = () => {
  storage.removeItem("token");
  storage.useSessionStorage();
  client.resetStore();

  return <Redirect to="/"/>
};

const Page = () => {
  const { loading, error, data } = useQuery(USER);
  if (!loading && !error && data && data.user) {
    storage.setItem("token", data.user.token);
  }

  return (
    <React.Fragment>
      <Header>
        <Link to="/">
          <Logo height={64} />
        </Link>
        <NavLinks data={data} />
      </Header>
      <Content>
        <Switch>
          <Route exact path="/" render={props => <Home {...props} loading={loading} error={error} data={data} />} />
          <Route path="/login/:token" component={Authenticate} />
          <Route path="/login" component={Login} />
          <Route path="/logout" component={Logout} />
          <Route path="/profile" component={Profile} />
          <Route path="/about" component={About} />
        </Switch>
      </Content>
      <Footer>
        <Link to="/" style={{marginLeft: "4px", textDecoration: "none"}}>twentythousand</Link>
        <Octopus height="1em" />
        <NavRight>
          <NavLink to="/about" style={{fontSize: "1em"}}>About</NavLink>
        </NavRight>
      </Footer>
    </React.Fragment>
  );
};

const App = () => (
  <ThemeProvider theme={theme}>
    <React.Fragment>
      <GlobalStyle />
      <Router>
        <Grid>
          <Page />
        </Grid>
      </Router>
    </React.Fragment>
  </ThemeProvider>
);

export {
  App as default,
  USER,
};
