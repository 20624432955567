import React, { useState } from 'react';
import { string, func } from 'prop-types';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import styled from 'styled-components/macro'

const LoginForm = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;

  h1 {
    display: flex;
    align-items: stretch;
    justify-content: center;
    margin: 8px;
    font-family: 'Righteous', cursive;
  }

  div,form {
    display: flex;
    align-items: stretch;
    justify-content: center;
    margin: 8px;
  }

  form input {
    font-family: 'Lato', sans-serif;
    font-size: 100%;
    border-style: none none solid;
    border-color: ${props => props.theme.white};
    background: none;
    color: ${props => props.theme.white};
    text-align: center;
  }

  form button {
    background: ${props => props.theme.tertiaryBrightest};
    color: ${props => props.theme.white};
    font-weight: bold;
    border: 2px solid ${props => props.theme.tertiaryBrightest};
    border-radius: 2px;
    margin-left: 8px;
    text-transform: uppercase;

    &:hover {
      background: ${props => props.theme.tertiaryBright};
    }

    &:active {
      background: ${props => props.theme.tertiary};
    }
  }
`;

const SubmittedMessage = ({ email }) => (
  <React.Fragment>
    <h1>Success!</h1>
    <div>
      We just sent an email to&nbsp;
      <strong>{email}</strong>.
      Click on the link in that email and you will be logged in.
    </div>
  </React.Fragment>
);

SubmittedMessage.propTypes = {
  email: string.isRequired,
};

const InProgressForm = ({ email, onChange, onSubmit }) => (
  <React.Fragment>
    <h1>Login</h1>
    <div>
      Enter your email and we'll send you a link to login.
    </div>
    <form onSubmit={onSubmit}>
      <input
        type="email"
        name="email"
        placeholder="you@example.com"
        autoFocus={true}
        required
        onChange={onChange}
        value={email}
      />
      <button type="submit">Get Link</button>
    </form>
  </React.Fragment>
);

InProgressForm.propTypes = {
  email: string.isRequired,
  onChange: func.isRequired,
  onSubmit: func.isRequired,
};

const EMAIL_TOKEN = gql`
  mutation EmailToken($email: String!) {
    emailToken(email: $email)
  }
`;

const Login = () => {
  const [email, setEmail] = useState("");
  const [emailToken, { called }] = useMutation(EMAIL_TOKEN);

  return (
      <LoginForm>
        {called ? (
          <SubmittedMessage email={email} />
        ) : (
          <InProgressForm
            email={email}
            onChange={event => setEmail(event.target.value)}
            onSubmit={event => {
              event.preventDefault();
              emailToken({ variables: { email } });
            }}
          />
        )}
      </LoginForm>
  );
}

export default Login;
