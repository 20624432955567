import { Timber } from '@timberio/browser';

const logger = (environment => {
  switch (environment) {
    case 'production':
      return new Timber(
        process.env.REACT_APP_TIMBER_API_KEY,
        process.env.REACT_APP_TIMBER_SOURCE_ID,
        { ignoreExceptions: true },
      );
    default:
      return new Proxy(console, {
        get: (obj, prop) => async (...args) => obj[prop](...args),
      })
  }
})(process.env.NODE_ENV);

export { logger as default };
