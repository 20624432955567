import React, { useState } from 'react';
import { gql } from 'apollo-boost';
import { useMutation, useQuery } from '@apollo/react-hooks';
import styled from 'styled-components/macro'
import Spinner from './Spinner';
import storage from './storage';

const KeepLoggedIn = () => {
  const [checked, setChecked] = useState(!!storage.getItem("useLocalStorage"));

  return (
    <label>
      <input
        type="checkbox"
        checked={checked}
        onChange={() => {
          storage.toggle();
          setChecked(!checked);
        }}
      />
      Keep me logged in on this browser
    </label>
  );
}

const FormContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;

  h1 {
    display: flex;
    align-items: stretch;
    justify-content: center;
    margin: 8px;
    font-family: 'Righteous', cursive;
  }

  div {
    display: flex;
    align-items: stretch;
    justify-content: center;
    margin: 8px;
  }

  form input {
    font-family: 'Lato', sans-serif;
    font-size: 100%;
    border-style: none none solid;
    border-color: ${props => props.theme.white};
    background: none;
    color: ${props => props.theme.white};
    text-align: center;
  }

  form button {
    background: ${props => props.theme.tertiaryBrightest};
    color: ${props => props.theme.white};
    font-weight: bold;
    border: 2px solid ${props => props.theme.tertiaryBrightest};
    border-radius: 2px;
    margin-top: 8px;
    text-transform: uppercase;

    &:hover {
      background: ${props => props.theme.tertiaryBright};
    }

    &:active {
      background: ${props => props.theme.tertiary};
    }
  }
`;

const ProfileForm = ({ firstName, onFirstNameChange, lastName, onLastNameChange, onSubmit }) => (
  <React.Fragment>
    <h1>Profile</h1>
    <form onSubmit={onSubmit}>
      <div>
        <label htmlFor="firstName">First Name</label>
        <input
          id="firstName"
          type="string"
          name="firstName"
          required
          onChange={onFirstNameChange}
          value={firstName}
        />
      </div>
      <div>
        <label htmlFor="lastName">Last Name</label>
        <input
          id="lastName"
          type="string"
          name="lastName"
          required
          onChange={onLastNameChange}
          value={lastName}
        />
      </div>
      <div>
        <button type="submit">{firstName ? "Save Changes" : "Create User"}</button>
      </div>
      <div><KeepLoggedIn /></div>
    </form>
  </React.Fragment>
);

const USER = gql`
  {
    user {
      firstName
      lastName
    }
  }
`;

const ProfileData = () => {
  const { loading, error, data } = useQuery(USER);
  if (loading) return <Spinner />;
  if (error) return <p>Error</p>;

  const firstName = data.user && data.user.firstName;
  const lastName = data.user && data.user.lastName;

  return (
    <Profile
      initialFirstName={firstName}
      initialLastName={lastName}
    />
  );
};

const EDIT_USER = gql`
  mutation EditUser($firstName: String, $lastName: String) {
    editUser(firstName: $firstName, lastName: $lastName) {
      firstName
      lastName
      token
    }
  }
`;

const Profile = ({ initialFirstName, initialLastName }) => {
  const [firstName, setFirstName] = useState(initialFirstName);
  const [lastName, setLastName] = useState(initialLastName);
  const [editUser,] = useMutation(EDIT_USER, {
    onCompleted: ({ editUser: { firstName, lastName, token } }) => {
      setFirstName(firstName);
      setLastName(lastName);
      storage.setItem("token", token);
    },
    refetchQueries: () => ["User"],
  });

  return (
    <FormContainer>
      <ProfileForm
        firstName={firstName}
        onFirstNameChange={event => setFirstName(event.target.value)}
        lastName={lastName}
        onLastNameChange={event => setLastName(event.target.value)}
        onSubmit={event => {
          event.preventDefault();
          editUser({ variables: { firstName, lastName } });
        }}
      />
    </FormContainer>
  );
}

export default ProfileData;
