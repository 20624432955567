import React from 'react';
import { bool, object, string, shape } from 'prop-types';
import Spinner from './Spinner';

const Home = ({ data, error, loading }) => {
  if (loading) return <Spinner />;
  if (error) return <p>Hello, World!</p>;
  if (data && data.user) return <p>Hello, {data.user.firstName}!</p>;
  return null
};

Home.propTypes = {
  data: shape({
    user: shape({
      firstName: string.isRequired,
    }),
  }),
  error: object,
  loading: bool,
};

Home.defaultProps = {
  loading: false,
};

export default Home;
