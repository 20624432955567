import React, { useState } from 'react';
import { shape, string } from 'prop-types';
import { Redirect } from 'react-router-dom';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import Spinner from './Spinner';
import client from './client';
import storage from './storage';

const LOGIN = gql`
  mutation Login($token: String!) {
    login(token: $token) {
      userToken
      loginToken
    }
  }
`;

const Authenticate = ({ match }) => {
  const token = decodeURI(match.params.token);
  const [redirectTo, setRedirectTo] = useState();
  const [login, { called, loading }] = useMutation(LOGIN, {
    onCompleted: ({ login: { userToken, loginToken} }) => {
      if (userToken) {
        storage.setItem("token", userToken);
        client.resetStore();
        setRedirectTo("/");
      }
      else if (loginToken) {
        storage.setItem("token", loginToken);
        client.resetStore();
        setRedirectTo("/profile");
      }
    }
  });

  if (!called) {
    login({ variables: { token } });
    return <Spinner />;
  }
  if (loading) return <Spinner />;
  if (redirectTo) return <Redirect to={redirectTo} />;
  return null;
}

Authenticate.propTypes = {
  match: shape({
    params: shape({
      token: string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Authenticate;
