import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { ApolloLink, from } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import logger from './logger';
import storage from './storage';

const uri = process.env.REACT_APP_GRAPHQL_ENDPOINT || "http://localhost:4000";

const httpLink = createHttpLink({ uri });

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = storage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const loggingLink = new ApolloLink((operation, forward) => {
  logger.log(operation);
  return forward(operation);
});

export default new ApolloClient({
  link: from([
    authLink,
    loggingLink,
    httpLink,
  ]),
  cache: new InMemoryCache()
});
